import React, { useState } from 'react';
import { AssetPair } from '../sharedInterfaces';

const NULL = "NULL";

interface InputCellFlipperProps {
    value: string | number,
    columnName: string,
    isEditable: boolean,
    containingRow: AssetPair,
    updatePair: Function
}

function InputCellFlipper(props: InputCellFlipperProps) {
    const [isCell, setIsCell] = useState(true);
    const [cellText, setCellText] = useState(props.value)
    const [editText, setEditText] = useState(props.value)

    function onCellClick(): void {
        if (props.isEditable && isCell) setIsCell(false);
    }

    function cancelCellEdit(): void {
        setEditText(cellText);
        setIsCell(true);
    }

    async function approveCellEdit(): Promise<void> {
        if (props.columnName === "name" && (editText === "" || editText === NULL || editText === NULL.toLowerCase())) {
            alert("The 'name' column can't be null or empty");
            setEditText(cellText);
            return;
        }
        if (cellText === editText) {
            cancelCellEdit();
            return;
        }
        if (window.confirm(`Are you sure you want to change the value from ${cellText === "" ? NULL : `"${cellText}"`} to ${editText === "" ? NULL : `"${editText}"`}?`)) {
            const colName = props.columnName;
            const newVal = editText;
            const id = props.containingRow.id;
            props.updatePair(colName, newVal as string, id);
            setCellText(editText);
            setIsCell(true);
        }
    }

    return <>
        {isCell

            ?

            <td
                onClick={onCellClick}
                style={{ opacity: cellText === "" ? 0.5 : 1 }}
            >
                {cellText === "" ? NULL : cellText}
            </td>

            :

            <td>
                <div className='level'>
                    <input
                        value={editText}
                        className='level-item'
                        style={{ width: "65%" }}
                        onChange={(e) => setEditText(e.target.value)}
                    />
                    <span
                        className="icon level-item"
                    >
                        <i className="fa-solid fa-circle-check has-text-success"
                            style={{ cursor: "pointer" }}
                            onClick={approveCellEdit}
                        />
                        <i className="fa-solid fa-circle-xmark has-text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={cancelCellEdit}
                        />
                    </span>
                </div>
            </td>
        }
    </>
}

export default InputCellFlipper;