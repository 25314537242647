import React, { MouseEventHandler } from "react";
import PropTypes from "prop-types";
import logo from "./logo.png";
import Datastore from "./Datastore";
import TradingAndPrices from "./TradingAndPrices";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
} from "react-router-dom";
import { ApiClient, GoogleProfile } from "./sharedInterfaces";
import EditablePairsTable from "./EditableTable/EditablePairsTable";

interface DataConsoleProps {
    apiClient: ApiClient,
    onSignOut: MouseEventHandler<HTMLButtonElement>,
    profile: null | GoogleProfile
}

class DataConsole extends React.Component<DataConsoleProps, any> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    render() {
        return (
            <Router>
                <nav
                    className="navbar"
                    role="navigation"
                    aria-label="main navigation"
                >
                    <div className="container">
                        <div className="navbar-brand">
                            <a className="navbar-item" href="/">
                                <img src={logo} />
                            </a>
                        </div>

                        <div id="navbarBasicExample" className="navbar-menu">
                            <div className="navbar-start">
                                <NavLink
                                    to="/"
                                    className="navbar-item"
                                    activeClassName="is-active"
                                >
                                    Trading & Prices
                                </NavLink>
                                <NavLink
                                    to="/historical_data"
                                    className="navbar-item"
                                    activeClassName="is-active"
                                >
                                    Historical Data
                                </NavLink>
                                <NavLink
                                    to="/edit_database"
                                    className="navbar-item"
                                    activeClassName="is-active"
                                >
                                    Edit Database
                                </NavLink>
                            </div>
                            <div className="navbar-end">
                                <div className="navbar-item">
                                    <div className="buttons">
                                        <button
                                            className="button is-light"
                                            onClick={this.props.onSignOut}
                                        >
                                            Sign Out
                                        </button>
                                    </div>
                                </div>
                                <div className="navbar-item">
                                    <img
                                        className="image"
                                        src={this.props.profile ? this.props.profile.imageUrl : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <Switch>
                    <Route path="/edit_database">
                        <EditablePairsTable apiClient={this.props.apiClient} />
                    </Route>
                    <Route path="/historical_data">
                        <Datastore apiClient={this.props.apiClient} />
                    </Route>
                    <Route path="/">
                        <TradingAndPrices apiClient={this.props.apiClient} />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default DataConsole;
