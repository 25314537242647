import React from 'react';
import PropTypes from 'prop-types';
import PairsButtons from './PairsButtons'
import { ApiClient, AssetPair, Exchange } from '../sharedInterfaces';

const DEFAULT_EXCHANGE = { id: 1, name: "Coinbase" };


interface SpreadExporterProps {
    defaultPair: string,
    apiClient: ApiClient
}

interface SpreadExporterState {
    exchange: Exchange,
    selectedPairs: AssetPair[],
    loading: boolean,
    error: any,
    pair: string,
    start: string,
    end: string,
    item: any,
    availableExchanges: Exchange[]
}

class SpreadExporter extends React.Component<SpreadExporterProps, SpreadExporterState> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: SpreadExporterProps) {
        super(props);
        const selectedExchange = localStorage.getItem("selectedExchangeSpread");
        const exchange = selectedExchange === null ? DEFAULT_EXCHANGE : JSON.parse(selectedExchange)

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onToggleAssetPair = this.onToggleAssetPair.bind(this);
        this.setSelectedPairs = this.setSelectedPairs.bind(this);

        this.state = {
            loading: false,
            selectedPairs: JSON.parse(localStorage.getItem(`exportIds_${exchange.id}_spread`) || "[]"),
            exchange,
            error: null,
            pair: props.defaultPair,
            start: (new Date(Date.now() - 24 * 3600 * 1000)).toLocaleDateString(),
            end: (new Date(Date.now() - 24 * 3600 * 1000)).toLocaleDateString(),
            item: null,
            availableExchanges: []
        };
    }

    async componentDidMount() {
        const selectedExchange = localStorage.getItem("selectedExchangeSpread");
        const exchange = selectedExchange === null ? DEFAULT_EXCHANGE : JSON.parse(selectedExchange)
        const availableExchanges: Exchange[] = [DEFAULT_EXCHANGE];
        this.setState({availableExchanges}) ;
        // @ts-ignore
        const selectedPairs = JSON.parse(localStorage.getItem(`exportIds_${exchange.id}_spread`) || "[]").filter((item: AssetPair) => item[`${this.state.exchange.name.toLowerCase()}_id`] !== null);
        console.log("reloading", selectedPairs)
        this.setState({ selectedPairs, exchange })
    }

    onToggleAssetPair(pair: AssetPair) {
        console.log("asset pair chosen: ", pair)
        if (this.state.selectedPairs.map((pair: AssetPair) => pair.id).includes(pair.id)) {
            const selectedPairs = this.state.selectedPairs.filter((prod: AssetPair) => prod.id !== pair.id);
            this.setState({
                selectedPairs
            })
            localStorage.setItem(`exportIds_${this.state.exchange.id}_spread`, JSON.stringify(selectedPairs))
        } else {
            const selectedPairs = this.state.selectedPairs.concat(pair).sort((a: AssetPair, b: AssetPair) => (a.name > b.name) ? 1 : -1)
            this.setState({
                selectedPairs
            })
            localStorage.setItem(`exportIds_${this.state.exchange.id}_spread`, JSON.stringify(selectedPairs))
        }
    }

    setSelectedPairs(selectedPairs: AssetPair[]) {
        this.setState({ selectedPairs })
        localStorage.setItem(`exportIds_${this.state.exchange.id}_spread`, JSON.stringify(selectedPairs))
    }




    handleSubmit(event: any) {

        event.preventDefault();

        console.log(event)

        this.setState({ item: null, error: null, loading: true })

        if (!Date.parse(this.state.start)) {
            //window.alert("Please provide a valid start date");
            this.setState({ error: "Please provide a valid start date" })
            return;
        }
        if (!Date.parse(this.state.end)) {
            this.setState({ error: "Please provide a valid end date" })
            return;
        }

        const url = `/api/datastore/coinbase/spread?pairs=${this.state.selectedPairs.map((pair: AssetPair) => pair.id).join(',')}&start=${this.state.start}&end=${this.state.end}&format=csv`;
        console.log(url);
        this.props.apiClient.download(url);
    }

    formatDate(date: string | number | Date) {
        const d = new Date(date).toUTCString()
        return d;
    }

    render() {


        return (

            <article className="box has-background-light">
                <h2 className="title">Spread Analysis</h2>
                <form onSubmit={this.handleSubmit}>
                <div className="field">
                        <label className="label">Exchange</label>
                        <div className="control">
                            <div className="select">
                                <select
                                    value={this.state.exchange.id}
                                    onChange={(event) => {
                                        const newExchange = this.state.availableExchanges[parseInt(event.target.value) - 1]
                                        //@ts-ignore
                                        const newSelectedPairs = JSON.parse(localStorage.getItem(`exportIds_${newExchange.id}_spread`) || "[]")
                                        this.setState({
                                            exchange: newExchange,
                                            selectedPairs: newSelectedPairs
                                        })
                                        localStorage.setItem("selectedExchangeSpread", JSON.stringify(newExchange))
                                        localStorage.setItem(`exportIds_${newExchange.id}_spread`, JSON.stringify(newSelectedPairs))
                                    }}>
                                    {this.state.availableExchanges.map((item) => (
                                        <option 
                                            key={item.id} 
                                            value={item.id}
                                        >
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <PairsButtons
                        key="pairs"
                        exchangePrefix={this.state.exchange.name.toLowerCase()}
                        selectedPairs={this.state.selectedPairs}
                        onToggleAssetPair={this.onToggleAssetPair}
                        apiClient={this.props.apiClient}
                        setSelectedPairs={this.setSelectedPairs}
                    />

                    <div className="field">
                        <label className="label">Start Date</label>
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.start} onChange={(event) => { this.setState({ start: event.target.value }) }} />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">End Date</label>
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.end} onChange={(event) => { this.setState({ end: event.target.value }) }} />
                        </div>
                    </div>

                    <div className="field">

                        <div className="control">
                            <input type="submit" value="Export" className="button  is-link" />
                        </div>
                    </div>

                </form>
                {this.state.error ? <div className="notification is-danger mt-2">{this.state.error.message}</div> : null}

            </article>



        );
    }

}


export default SpreadExporter
