import React from 'react';
import PropTypes from 'prop-types';
import DailyPriceExport from './components/DailyPriceExport'
import FillsExport from './components/FillsExport';
import LSRExporter from './components/LSRExporter'
import { ApiClient } from './sharedInterfaces';
import TradeReconciliation from "./components/TradeReconciliation";
import AttributedFillsExport from "./components/AttributedFillsExport";
import LeverageZScoreExporter from './components/LeverageZScoreExporter';
import DailySpreadExporter from './components/DailySpreadExporter';

interface TradingAndPricesProps {
    apiClient: ApiClient
}

function TradingAndPrices(props: TradingAndPricesProps) {
    return <section className="section">
        <div className="container">
            <FillsExport apiClient={props.apiClient} />
            <AttributedFillsExport apiClient={props.apiClient} />
            <TradeReconciliation apiClient={props.apiClient}/>
            <DailyPriceExport apiClient={props.apiClient} />
            <LSRExporter apiClient={props.apiClient} />
            <LeverageZScoreExporter apiClient={props.apiClient} />
            <DailySpreadExporter apiClient={props.apiClient} />
        </div>
    </section>
}

TradingAndPrices.propTypes = {
    apiClient: PropTypes.object.isRequired,
}

export default TradingAndPrices
