import React from "react";
import PropTypes from "prop-types";
import { ApiClient } from "../sharedInterfaces";

interface LeverageZScoreExporterProps {
    apiClient: ApiClient;
}

interface LeverageZScoreExporterState {
    error: string | null;
    start: string;
    end: string;
}

class LeverageZScoreExporter extends React.Component<
    LeverageZScoreExporterProps,
    LeverageZScoreExporterState
> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: LeverageZScoreExporterProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            error: null,
            start: new Date().toLocaleDateString(),
            end: new Date().toLocaleDateString(),
        };
    }

    async handleSubmit(event: any) {
        event.preventDefault();

        this.setState({ error: null });

        if (!Date.parse(this.state.start)) {
            this.setState({ error: "Please provide a valid start date" });
            return;
        }

        if (!Date.parse(this.state.end)) {
            this.setState({ error: "Please provide a valid end date" });
            return;
        }

        const start = this.state.start;
        const end = this.state.end;
        this.props.apiClient.download(
            `/api/preprocess/leverage_zscores?start_date=${start}&end_date=${end}`
        );
    }

    formatDate(date: string | number | Date) {
        const d = new Date(date).toUTCString();
        return d;
    }

    render() {
        return (
            <article className="box has-background-light">
                <h2 className="title">Leverage Z Score Export</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="field is-grouped">
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                defaultValue={this.state.start}
                                onChange={(event) => {
                                    this.setState({ start: event.target.value });
                                }}
                            />
                        </div>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                defaultValue={this.state.end}
                                onChange={(event) => {
                                    this.setState({ end: event.target.value });
                                }}
                            />
                        </div>
                        <div className="control">
                            <input
                                type="submit"
                                value="Query"
                                className="button  is-link"
                            />
                        </div>
                    </div>
                </form>
                {this.state.error ? (
                    <div className="notification is-danger mt-2">
                        {this.state.error}
                    </div>
                ) : null}
            </article>
        );
    }
}

export default LeverageZScoreExporter;
