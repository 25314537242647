import { PriceObject } from "../sharedInterfaces";

export function filterDaily(numDays: number, endTimestamp: number, prices: PriceObject[]) {
    return prices.sort((a: PriceObject, b: PriceObject)=>Date.parse(b.date.toString())-Date.parse(a.date.toString())).filter(v=>Date.parse(v.date.toString())<=endTimestamp).slice(0,numDays)
}

export function filterDailyByTimestamp(numDays: number, endTimestamp: number, prices: PriceObject[]) {
    return prices.sort((a,b)=>b.time-a.time).filter(v=>v.time<=endTimestamp).slice(0,numDays)
}

export function startOfDayUTC(timestamp: number) {
    let date = new Date(timestamp);
    let utc = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
    return utc;
}

export function getDateByNumDaysBackUTC(timestamp: number, numDaysBack: number) {
    let date = startOfDayUTC(timestamp);
    date.setUTCDate(date.getUTCDate() - numDaysBack)
    return date;
}

export function startOfPreviousDayUTC(timestamp: number) {
    let date = startOfDayUTC(timestamp);
    date.setUTCDate(date.getUTCDate() -1)
    return date;
}