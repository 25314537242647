import React from 'react';
import PropTypes from 'prop-types';
import PairsButtons from './PairsButtons'
import { ApiClient, AssetPair, Exchange } from '../sharedInterfaces';

const possibleResolutions = ['Minute', 'Hour', 'Day']
const DEFAULT_EXCHANGE = { id: 1, name: "Coinbase" };
interface DataExplorerProps {
    apiClient: ApiClient,
}

interface DataExplorerState {
    loading: boolean,
    selectedPairs: AssetPair[],
    exchange: Exchange,
    start: string,
    end: string,
    resolution: string,
    offset: string,
    error: string,
    availableExchanges: Exchange[]
}

class DataExplorer extends React.Component<DataExplorerProps, DataExplorerState> {

    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };
    constructor(props: DataExplorerProps) {
        super(props);
        const selectedExchange = localStorage.getItem("selectedExchange");
        const exchange = selectedExchange === null ? DEFAULT_EXCHANGE : JSON.parse(selectedExchange)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onToggleAssetPair = this.onToggleAssetPair.bind(this)
        this.setSelectedPairs = this.setSelectedPairs.bind(this)
        this.state = {
            loading: false,
            selectedPairs: JSON.parse(localStorage.getItem(`exportIds_${exchange.id}`) || "[]"),
            exchange,
            start: (new Date(Date.now() - 7 * 24 * 3600 * 1000)).toLocaleDateString(),
            end: (new Date()).toLocaleDateString(),
            resolution: possibleResolutions[0],
            offset: "0",
            error: "",
            availableExchanges: []
        };
    }

    async fetchExchanges() {
        const exchangesApi = `/api/exchanges`;
        return this.props.apiClient.get(exchangesApi)
            .then((res: Response) => res.json()
                .catch(() => {
                    throw new Error(`Call to ${exchangesApi} failed with STATUS: ${res.status} ${res.statusText}`);
                }));
    }

    async componentDidMount() {
        const selectedExchange = localStorage.getItem("selectedExchange");
        const exchange = selectedExchange === null ? DEFAULT_EXCHANGE : JSON.parse(selectedExchange)
        const availableExchanges: Exchange[] = await this.fetchExchanges();
        this.setState({ availableExchanges });
        // @ts-ignore
        const selectedPairs = JSON.parse(localStorage.getItem(`exportIds_${exchange.id}`) || "[]").filter((item: AssetPair) => item[`${this.state.exchange.name.toLowerCase()}_id`] !== null);
        console.log("reloading", selectedPairs)
        this.setState({ selectedPairs, exchange })
    }

    onToggleAssetPair(pair: AssetPair) {
        console.log("asset pair chosen: ", pair)
        if (this.state.selectedPairs.map((pair: AssetPair) => pair.id).includes(pair.id)) {
            const selectedPairs = this.state.selectedPairs.filter((prod: AssetPair) => prod.id !== pair.id);
            this.setState({
                selectedPairs
            })
            localStorage.setItem(`exportIds_${this.state.exchange.id}`, JSON.stringify(selectedPairs))
        } else {
            const selectedPairs = this.state.selectedPairs.concat(pair).sort((a: AssetPair, b: AssetPair) => (a.name > b.name) ? 1 : -1)
            this.setState({
                selectedPairs
            })
            localStorage.setItem(`exportIds_${this.state.exchange.id}`, JSON.stringify(selectedPairs))
        }
    }

    setSelectedPairs(selectedPairs: AssetPair[]) {
        this.setState({ selectedPairs })
        localStorage.setItem(`exportIds_${this.state.exchange.id}`, JSON.stringify(selectedPairs))
    }

    handleSubmit(event: any) {

        event.preventDefault();
        this.setState({ error: "" })
        console.log(this.state);

        if (!Date.parse(this.state.start)) {
            this.setState({ error: "Please provide a valid start date" })
            return;
        }
        if (!Date.parse(this.state.end)) {
            this.setState({ error: "Please provide a valid end date" })
            return;
        }

        const url = this.state.resolution === "Day"
            ? `/api/candlestore/${this.state.exchange.id}/prices?pairs=${this.state.selectedPairs.map((pair: AssetPair) => pair.id).join(',')}&format=csv&start=${this.state.start}&end=${this.state.end}&offset=${this.state.offset}`
            : `/api/candlestore/${this.state.exchange.id}/candles?resolution=${this.state.resolution.toLowerCase()}&pairs=${this.state.selectedPairs.map((pair: AssetPair) => pair.id).join(',')}&format=csv&start=${this.state.start}&end=${this.state.end}`;
        console.log(url);
        this.props.apiClient.download(url);
    }

    render() {
        return (
            <>
                <form key="form" onSubmit={this.handleSubmit}>

                    <div className="field">
                        <label className="label">Exchange</label>
                        <div className="control">
                            <div className="select">
                                <select
                                    value={this.state.exchange.id}
                                    onChange={(event) => {
                                        const newExchange = this.state.availableExchanges[parseInt(event.target.value) - 1]
                                        //@ts-ignore
                                        const newSelectedPairs = JSON.parse(localStorage.getItem(`exportIds_${newExchange.id}`) || "[]")
                                        this.setState({
                                            exchange: newExchange,
                                            selectedPairs: newSelectedPairs
                                        })
                                        localStorage.setItem("selectedExchange", JSON.stringify(newExchange))
                                        localStorage.setItem(`exportIds_${newExchange.id}`, JSON.stringify(newSelectedPairs))
                                    }}>
                                    {this.state.availableExchanges.map((item) => (
                                        <option 
                                            key={item.id} 
                                            value={item.id}
                                        >
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <PairsButtons
                        key="pairs"
                        exchangePrefix={this.state.exchange.name.toLowerCase()}
                        selectedPairs={this.state.selectedPairs}
                        onToggleAssetPair={this.onToggleAssetPair}
                        apiClient={this.props.apiClient}
                        setSelectedPairs={this.setSelectedPairs}
                    />

                    <div className="field">
                        <label className="label">Start Date</label>
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.start} onChange={(event) => { this.setState({ start: event.target.value }) }} />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">End Date</label>
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.end} onChange={(event) => { this.setState({ end: event.target.value }) }} />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Candle Duration</label>
                        <div className="control">
                            <div className="select">
                                <select onChange={(event) => {
                                    console.log(event.target.value)
                                    this.setState({ resolution: event.target.value })
                                }} defaultValue={this.state.resolution}>
                                    {possibleResolutions.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {this.state.resolution !== "Day"
                        ?
                        null
                        :
                        <div className="field">
                            <label className="label">Hour Offset</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="0" value={this.state.offset} onChange={(event) => { this.setState({ offset: event.target.value }) }} />
                            </div>
                        </div>

                    }

                    <div className="field">

                        <div className="control">
                            <input type="submit" value="Export" className="button  is-link" />
                        </div>
                    </div>

                    {this.state.error ?
                        <div className="notification is-danger">
                            {this.state.error}
                        </div> : <span></span>
                    }

                </form>
            </>
        );
    }

}

DataExplorer.propTypes = {
    apiClient: PropTypes.object.isRequired,
};

export default DataExplorer
