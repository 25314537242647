import React from 'react';
import PropTypes from 'prop-types';
import {ApiClient, AssetPair} from '../sharedInterfaces';
import EditableRow from "../EditableTable/EditableRow";

interface TradeReconciliationProps {
    apiClient: ApiClient
}

interface TradeReconciliationState {
    loading: boolean,
    loaded: boolean,
    startDate: string,
    endDate: string,
    unmatchedFills: any[],
    unreconciledTrades: any[]
}

export default class TradeReconciliation extends React.Component<TradeReconciliationProps, TradeReconciliationState> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: TradeReconciliationProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        const date = new Date();
        const date2 = new Date(date.getTime() + 24 * 2600 * 1000)

        this.state = {
            loading: false,
            loaded: false,
            startDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
            endDate: `${date2.getMonth() + 1}/${date2.getDate()}/${date2.getFullYear()}`,
            unmatchedFills: [],
            unreconciledTrades: []
        };
    }

    async handleSubmit(event: any) {

        event.preventDefault();

        if (!Date.parse(this.state.startDate)) {
            window.alert("Please provide a valid start date");
            return;
        }

        if (!Date.parse(this.state.endDate)) {
            window.alert("Please provide a valid end date");
            return;
        }

        this.setState({
            loaded: false,
            loading: true
        })

        let response = await this.props.apiClient.get(`/api/reconciled_fills?match=unmatchedFills&startDate=${this.state.startDate}&endDate=${this.state.endDate}`)
        let unmatchedFills = await response.json();
        response = await this.props.apiClient.get(`/api/reconciled_fills?match=unreconciledTrades&startDate=${this.state.startDate}&endDate=${this.state.endDate}`)
        let unreconciledTrades = await response.json();

        this.setState({
            unmatchedFills,
            unreconciledTrades,
            loaded:true,
            loading:false});
    }

    render() {
        return (
            <article className="box has-background-light">
                <div className="block">
                <h2 className="title">Check Reconciliation Status</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="field is-grouped">
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.startDate} onChange={(event) => { this.setState({ startDate: event.target.value }) }} />
                        </div>
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.endDate} onChange={(event) => { this.setState({ endDate: event.target.value }) }} />
                        </div>
                        <div className="control">
                            <input type="submit" value="Reconcile" className="button  is-link" />
                        </div>
                    </div>
                </form>
                </div>

                {this.state.loading && <span><i className="fas fa-spinner fa-pulse"></i></span>}

                {(this.state.loaded && (this.state.unreconciledTrades.length + this.state.unmatchedFills.length === 0)) &&
                    <div className="block">
                    <div className="notification is-success">
                        All trades reconciled!
                    </div></div>}

                {(this.state.loaded && (this.state.unreconciledTrades.length + this.state.unmatchedFills.length > 0)) &&
                <div className="block">
                    <div className="block">
                    <h3>Unreconciled Heavenly Trades</h3>
                    <table className='table is-bordered is-striped' >
                        <thead>
                        <tr>
                            <th>Pair</th>
                            <th>Price</th>
                            <th>Size</th>
                            <th>Side</th>
                            <th>Fee</th>
                            <th>Date</th>
                            <th>ID</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.unreconciledTrades.map((trade) =>
                                <tr>
                                    <td>{trade.instrumentName}</td>
                                    <td>{trade.price}</td>
                                    <td>{trade.size}</td>
                                    <td>{trade.side}</td>
                                    <td>{trade.fee}</td>
                                    <td>{trade.completedAt}</td>
                                    <td>{trade.externalId}</td>
                                </tr>)
                        }
                        </tbody>
                    </table>
                    </div>
                    <div className="block">
                    <h3>Unmatched Fills from Counterparty</h3>
                    <table className='table is-bordered is-striped' >
                        <thead>
                        <tr>
                            <th>Pair</th>
                            <th>Price</th>
                            <th>Size</th>
                            <th>Side</th>
                            <th>Fee</th>
                            <th>Date</th>
                            <th>ID</th>
                            <th>Platform</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.unmatchedFills.map((trade) =>
                                <tr>
                                    <td>{trade.instrumentName}</td>
                                    <td>{trade.price}</td>
                                    <td>{trade.size}</td>
                                    <td>{trade.side}</td>
                                    <td>{trade.fee}</td>
                                    <td>{trade.completedAt}</td>
                                    <td>{trade.tradeId}</td>
                                    <td>{trade.platformName}</td>
                                </tr>)
                        }
                        </tbody>
                    </table>
                    </div>
                </div>}
            </article>
        );
    }
}
