import React from "react";
import PropTypes from "prop-types";
import { ApiClient } from "../sharedInterfaces";

interface DailySpreadExporterProps {
    apiClient: ApiClient
}

interface DailySpreadExporterState {
    error: string | null,
    start: string,
    end: string,
}


class DailySpreadExporter extends React.Component<DailySpreadExporterProps, DailySpreadExporterState> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: DailySpreadExporterProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            error: null,
            start: new Date().toLocaleDateString(),
            end: new Date().toLocaleDateString(),
        };
    }

    async handleSubmit(event: any) {
        event.preventDefault();

        this.setState({ error: null });

        if (!Date.parse(this.state.start)) {
            this.setState({ error: "Please provide a valid start date" });
            return;
        }
        if (!Date.parse(this.state.end)) {
            this.setState({ error: "Please provide a valid end date" });
            return;
        }

        this.props.apiClient.download(
            `/api/datastore/coinbase/spread?start=${this.state.start}&end=${this.state.end}&format=csv`
        );
    }

    formatDate(date: string | number | Date) {
        const d = new Date(date).toUTCString();
        return d;
    }

    render() {
        return (
            <article className="box has-background-light">
                <h2 className="title">Coinbase Spreads Export</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="field is-grouped">
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                defaultValue={this.state.start}
                                onChange={(event) => {
                                    this.setState({
                                        start: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                defaultValue={this.state.end}
                                onChange={(event) => {
                                    this.setState({ end: event.target.value });
                                }}
                            />
                        </div>
                        <div className="control">
                            <input
                                type="submit"
                                value="Query"
                                className="button  is-link"
                            />
                        </div>
                    </div>
                </form>
                {this.state.error ? (
                    <div className="notification is-danger mt-2">
                        {this.state.error}
                    </div>
                ) : null}
            </article>
        );
    }
}

export default DailySpreadExporter;
