import React, { useState } from "react";
import { AssetPair } from "../sharedInterfaces";

interface AutocompleteProps {
  name: string
  placeholder: string
  data: AssetPair[]
  selectItem: Function
}

export default function Autocomplete(props: AutocompleteProps) {

const [activeIndex, setActiveIndex] = useState(0);
const [matches, setMatches] = useState<AssetPair[]>([]);
const [query, setQuery] = useState("");

  function handleKeyPress(event: any) {
    switch (event.which) {
      case 13: // Enter key
        if (matches.length) {
          handleSelection(event, matches[activeIndex])
        }
        break;
      case 38: // Up arrow
        setActiveIndex(activeIndex >= 1 ? activeIndex - 1 : 0);
        break;
      case 40: // Down arrow
      setActiveIndex(activeIndex < matches.length - 1 ? activeIndex + 1 : matches.length - 1);
        break;
      default:
        break;
    }
  }

  function handleSelection(event: any, selection: AssetPair) {
    event.preventDefault();
    setActiveIndex(0)
    setMatches([])
    setQuery("")
    props.selectItem(selection);
  }

  function updateQuery(e: any) {
    const { data } = props;
    const query = e.target.value;
    setMatches(query.length >= 1
        ? data.filter((item: AssetPair) => item.name.toUpperCase().indexOf(query.toUpperCase()) >= 0)
        : []);
        setQuery(query);
  }

    const { name, placeholder } = props;

    return (
      <div className="field">
        <div className="control">
          <div className={`dropdown ${matches.length > 0 ? "is-active" : ""}`}
          
          onBlur={ (event) =>  {
            if (!event.currentTarget.contains(event.relatedTarget)) {
              setMatches([]) 
              setQuery("")
            }
          } 
        }
        >
            <div className="dropdown-trigger">
              <input
                type="text"
                className="input"
                name={name}
                value={query}
                onChange={updateQuery}
                onKeyDown={handleKeyPress}
                placeholder={placeholder}
              />
            </div>
            <div className="dropdown-menu">
              {matches.length > 0 && (
                <div className="dropdown-content">
                  {matches.map((match: AssetPair, index: number) => (
                    <a
                      className={`dropdown-item ${index === activeIndex ? "is-active" : ""
                        }`}
                      href="/"
                      key={match.name}
                      onClick={event => handleSelection(event, match)}
                    >
                      {match.name}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  
}