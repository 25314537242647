import React from 'react';
import logo from './logo.png';
import ApiClient from './ApiClient';
import { ApiClient as IApiClient, GoogleProfile } from './sharedInterfaces';
import DataConsole from './DataConsole';
import '@fortawesome/fontawesome-free/css/all.min.css'

interface AppProps {
  apiClient: IApiClient,
}

interface AppState {
  isSignedIn: boolean,
  route: string,
  profile: null | GoogleProfile,
  apiClient: null | IApiClient
}

class App extends React.Component<AppProps, AppState> {

  GoogleAuth: any;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      route: "",
      isSignedIn: false,
      profile: null,
      apiClient: null
    };
  }

  onLoginSuccess() {
    const BasicProfile = this.GoogleAuth.currentUser.get().getBasicProfile()
    const profile = {
      id: BasicProfile.getId(),
      email: BasicProfile.getEmail(),
      imageUrl: BasicProfile.getImageUrl()
    }
    console.log(profile)
    const token = this.GoogleAuth.currentUser.get().getAuthResponse().id_token;
    this.setState({
      apiClient: new ApiClient(token, this.getNewToken),
      isSignedIn: true,
      profile
    });
  }

  signOut() {
    console.log("signing out")
    this.GoogleAuth.signOut().then(() => { window.location.reload() })

  }

  componentDidMount() {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
      }).then((GoogleAuth: any) => {
        this.GoogleAuth = GoogleAuth
        console.log("Google Auth inited", GoogleAuth)
        if (GoogleAuth.isSignedIn.get()) {
          this.onLoginSuccess()
        }

        var opts = {
          width: 200,
          onsuccess: this.onLoginSuccess.bind(this)
        }
        window.gapi.signin2.render('loginButton', opts)
      }, (e: any) => {
        console.error(e)
      })
    })

  }

// Refresh our Google oauth token
// If the user is sitting on the page for a long time, their oauth token may
// expire
// This function returns a promise for a fresh token
getNewToken = () => {
  return window.gapi.auth2.init({
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  }).then((GoogleAuth: any) => {
    this.GoogleAuth = GoogleAuth
    if (GoogleAuth.isSignedIn.get()) {
      const token = this.GoogleAuth.currentUser.get().getAuthResponse().id_token;
      return token;
    }
  });
}

  render() {
    if (!this.state.isSignedIn) {
      return <SignIn />
    } else {
      return (
        <DataConsole
          onSignOut={this.signOut.bind(this)}
          apiClient={this.state.apiClient! /* should not be null if isSignedOn is true */}
          profile={this.state.profile}
        />
      )
    }
  }
}

function SignIn() {
  return (<section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container has-text-centered">
        <div className="column is-4 is-offset-4">
          <p className="subtitle">Please sign in to proceed</p>
          <div className="box has-text-centered">
            <figure className="image mx-4">
              <img src={logo} />
            </figure>

            <div className="columns is-mobile is-centered mt-6 mb-2">
              <div className="column is-narrow">
                <div id="loginButton"></div>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </section>)
}

export default App;
