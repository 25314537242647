import React from 'react';
import PropTypes from 'prop-types';
import { ApiClient } from '../sharedInterfaces';

interface FillsExportProps {
    apiClient: ApiClient
}

interface FillsExportState {
    loading: boolean,
    exchange: string,
    startDate: string,
    endDate: string,
    close: string
}

export default class FillsExport extends React.Component<FillsExportProps, FillsExportState> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: FillsExportProps) {
        super(props);

        this.handleSubmitQuick = this.handleSubmitQuick.bind(this);

        const date = new Date();
        const date2 = new Date(date.getTime() + 24 * 2600 * 1000)

        this.state = {
            loading: false,
            exchange: 'coinbase',
            startDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
            endDate: `${date2.getMonth() + 1}/${date2.getDate()}/${date2.getFullYear()}`,
            close: 'midnight_utc',
        };
    }

    handleSubmitQuick(event: any) {

        event.preventDefault();

        if (!Date.parse(this.state.startDate)) {
            window.alert("Please provide a valid start date");
            return;
        }

        if (!Date.parse(this.state.endDate)) {
            window.alert("Please provide a valid end date");
            return;
        }

        this.props.apiClient.download(`/api/counterparty_fills?format=csv&startDate=${this.state.startDate}&endDate=${this.state.endDate}`);

    }

    render() {
        return (
            <article className="box has-background-warning-light">
                <h2 className="title">Fills Export</h2>
                <form onSubmit={this.handleSubmitQuick}>
                    <div className="field is-grouped">
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.startDate} onChange={(event) => { this.setState({ startDate: event.target.value }) }} />
                        </div>
                        <div className="control">
                            <input className="input" type="text" defaultValue={this.state.endDate} onChange={(event) => { this.setState({ endDate: event.target.value }) }} />
                        </div>
                        <div className="control">
                            <input type="submit" value="Fills Report" className="button  is-link" />
                        </div>
                    </div>
                </form>
            </article>
        );
    }
}
