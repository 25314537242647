class ApiClient {
    token: string;
    refreshTokenCallback: Function;
    constructor(token: string, refreshTokenCallback: Function) {
        this.token = token;
        this.refreshTokenCallback = refreshTokenCallback;
    }

    doRequest(method: "GET" | "POST" | "PATCH" | "PUT", url: string, numTries = 0, body = undefined) {
        if (numTries >= 3) {
            console.log("Gave up on request after", numTries);
            return Promise.reject();
        }

        const headers = new Headers();
        headers.append("Authorization", "Bearer g-" + this.token);
        headers.append("Content-Type", "application/json");
        const requestInit: RequestInit = { method: method, headers: headers, body: JSON.stringify(body) };
        body && (requestInit.body = JSON.stringify(body));
        return fetch(url, requestInit).then(
             (response: Response) => {
                if (response.status === 401) {
                    // Our request was unauthorized. The most likely reason is
                    // that we had a valid auth token which is now expired. In
                    // that case, we want to refresh the token and try again
                    // (up to a limited number of attempts)
                    return this.refreshTokenCallback().then(
                         (newToken: string) => {
                            this.token = newToken;
                            return this.doRequest(method, url, numTries + 1);
                        }
                    );
                } else {
                    return response;
                }
            }
        ).catch(err => console.log(err));
    }

    get(url: string) {
        return this.doRequest("GET", url);
    }

    post(url: string, body = undefined) {
        return this.doRequest("POST", url, 0, body);
    }

    patch(url: string, body = undefined) {
        return this.doRequest("PATCH", url, 0, body);
    }

    put(url: string, body = undefined) {
        return this.doRequest("PUT", url, 0, body);
    }

    download(url: string) {
        this.post("/api/auth/create_download_token")
            .then((response: Response) => response.json())
            .then(function (response) {
                const singleUseToken = response["token"];
                if (!url.includes("?")) {
                    url = url + "?";
                } else {
                    url = url + "&";
                }
                url = url + `token=${singleUseToken}`;
                window.open(url);
            });
    }
}

export default ApiClient;
