import React from "react";
import PropTypes from "prop-types";
import { ApiClient } from "../sharedInterfaces";

function juggleDate(date_str: string) {
    const [mm, dd, yyyy] = date_str.split("/");
    return yyyy + "/" + mm + "/" + dd;
}

interface LSRExporterProps {
    apiClient: ApiClient
}

interface LSRExporterState {
    error: string | null,
    start: string,
    end: string,
    granularity: string,
}

class LSRExporter extends React.Component<LSRExporterProps, LSRExporterState> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: LSRExporterProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            error: null,
            start: new Date().toLocaleDateString(),
            end: new Date().toLocaleDateString(),
            granularity: "day",
        };
    }

    async handleSubmit(event: any) {
        event.preventDefault();

        this.setState({ error: null });

        if (!Date.parse(this.state.start)) {
            this.setState({ error: "Please provide a valid start date" });
            return;
        }
        if (!Date.parse(this.state.end)) {
            this.setState({ error: "Please provide a valid end date" });
            return;
        }

        const start = juggleDate(this.state.start);
        const end = juggleDate(this.state.end);
        this.props.apiClient.download(
            `/api/lsr?start_date=${start}&end_date=${end}&resolution=${this.state.granularity}&format=csv`
        );
    }

    formatDate(date: string | number | Date) {
        const d = new Date(date).toUTCString();
        return d;
    }

    render() {
        return (
            <article className="box has-background-light">
                <h2 className="title">LSR Export</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="field is-grouped">
                        <div className="control">
                            <div className="select">
                                <select
                                    value={this.state.granularity}
                                    onChange={(event) =>
                                        this.setState({
                                            granularity: event.target.value,
                                        })
                                    }
                                >
                                    <option value="5min">5 minute</option>
                                    <option value="hour">Hour</option>
                                    <option value="day">Day</option>
                                </select>
                            </div>
                        </div>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                defaultValue={this.state.start}
                                onChange={(event) => {
                                    this.setState({
                                        start: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                defaultValue={this.state.end}
                                onChange={(event) => {
                                    this.setState({ end: event.target.value });
                                }}
                            />
                        </div>
                        <div className="control">
                            <input
                                type="submit"
                                value="Query"
                                className="button  is-link"
                            />
                        </div>
                    </div>
                </form>
                {this.state.error ? (
                    <div className="notification is-danger mt-2">
                        {this.state.error}
                    </div>
                ) : null}
            </article>
        );
    }
}

export default LSRExporter;
