import React, { useEffect, useState } from 'react';
import { ApiClient, AssetPair, Exchange } from '../sharedInterfaces';
import EditableRow from './EditableRow';
import NewRowModal from './NewRowModal';

interface EditablePairsTableProps {
    apiClient: ApiClient
}

const ALL_EXCHANGES = "All Exchanges";

function EditablePairsTable(props: EditablePairsTableProps) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState<any>(null);
    const [availableExchanges, setAvailableExchanges] = useState<Exchange[]>([]);
    const [selectedExchange, setSelectedExchange] = useState({ name: ALL_EXCHANGES, id: -1 });
    const [visiblePairs, setVisiblePairs] = useState([]);
    const [allPairs, setAllPairs] = useState([]);
    const [columns, setColumns] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);

    const fetchPairs = () => {
        const pairsApi = `/api/candlestore/pairs`;
        props.apiClient.get(pairsApi)
            .then((res: Response) => res.json()
                .catch(() => {
                    throw new Error(`Call to ${pairsApi} failed with STATUS: ${res.status} ${res.statusText}`);
                }))
            .then(
                (result: []) => {
                    const allItems: any = result.sort((a: AssetPair, b: AssetPair) => (a.name > b.name) ? 1 : -1);
                    setIsLoaded(true);
                    setAllPairs(allItems);
                    //@ts-ignore
                    setVisiblePairs(!selectedExchange || selectedExchange.name === ALL_EXCHANGES ? allItems : allItems.filter((item: AssetPair) => item[`${selectedExchange.name.toLocaleLowerCase()}_id`]))

                    // Set the order of the columns:
                    let orderedColumns = Object.keys(allItems[0]);
                    orderedColumns = orderedColumns.filter((el: string) => !["id", "name", "asset", "cross"].includes(el));
                    orderedColumns.unshift("cross");
                    orderedColumns.unshift("asset");
                    orderedColumns.unshift("name");
                    orderedColumns.unshift("id");
                    //@ts-ignore
                    if (allItems && allItems.length > 0) setColumns(orderedColumns);
                },
                (error: any) => {
                    console.error(error);
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    const fetchExchanges = () =>  {
        const exchangesApi = `/api/exchanges`;
        props.apiClient.get(exchangesApi)
            .then((res: Response) => res.json()
                .catch(() => {
                    throw new Error(`Call to ${exchangesApi} failed with STATUS: ${res.status} ${res.statusText}`);
                }))
            .then(
                (result: []) => {
                    setAvailableExchanges(result);
                },
                (error: any) => {
                    console.error(error);
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    const updatePair = async (colName: string, newValue: string, id: number) => {
        const updatePairApi = `/api/candlestore/update_pair`;
        await props.apiClient.patch(updatePairApi, { "columnName": colName, "newValue": newValue === "" ? null : newValue, "id": id })
            .then((res: Response) => res.json()
                .catch(() => {
                    throw new Error(`Call to ${updatePairApi} failed with STATUS: ${res.status} ${res.statusText}`);
                }))
            .then(
                (result: any) => {
                    if (result.error) {
                        setIsLoaded(true);
                        setError({ "message": result.error });
                    }
                },
                (error: any) => {
                    console.error(error);
                    setIsLoaded(true);
                    setError(error);
                }
            )
        fetchPairs();
    }

    const addNewPair = async (newAsset: any) => {
        const updatePairApi = `/api/candlestore/add_pair`;
        await props.apiClient.put(updatePairApi, newAsset)
            .then((res: Response) => res.json()
                .catch(() => {
                    throw new Error(`Call to ${updatePairApi} failed with STATUS: ${res.status} ${res.statusText}`);
                }))
            .then(
                (result: any) => {
                    if (result.error) {
                        setIsLoaded(true);
                        setError({ "message": result.error });
                    }
                },
                (error: any) => {
                    console.error(error);
                    setIsLoaded(true);
                    setError(error);
                }
            )
        fetchPairs();
    }

    useEffect(() => {
        fetchExchanges();
        fetchPairs();
    }, []);

    if (error) return <section className="section">
        <div className="container">
            <div className="notification is-danger">{error.message}
                <button
                    className="delete"
                    onClick={() => setError(null)}
                />
            </div>
        </div>
    </section>;
    else if (!isLoaded) return <section className="section"><div className="container"><progress className="progress is-small is-primary" max="100">15%</progress></div></section>;
    else return <section className="section">
        <div className="container is-fluid">
            <NewRowModal
                onClose={() => setDisplayModal(false)}
                displayModal={displayModal}
                addNewAssetPair={addNewPair}
            />
            <div className="field">
                <label className="label">Exchange</label>
                <div className="control">
                    <div className="select">
                        <select
                            defaultValue={selectedExchange.id}
                            onChange={(event) => {
                                if (parseInt(event.target.value) === -1) {
                                    setVisiblePairs(allPairs);
                                    setSelectedExchange({ name: ALL_EXCHANGES, id: -1 });
                                } else {
                                    const newExchange = availableExchanges[parseInt(event.target.value) - 1];
                                    //@ts-ignore
                                    const newVisiblePairs = allPairs.filter((item: AssetPair) => item[`${newExchange.name.toLocaleLowerCase()}_id`]);
                                    setVisiblePairs(newVisiblePairs);
                                    setSelectedExchange(newExchange);
                                }
                            }}>
                            {availableExchanges.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                            <option key={ALL_EXCHANGES} value={-1}>{ALL_EXCHANGES}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className='level-left'>
                <span className="icon level-item">
                    <i className="fa-solid fa-circle-plus has-text-success"
                        style={{ cursor: "pointer" }}
                        onClick={() => setDisplayModal(true)}
                    />
                </span>
                <span className='level-item'>Click to add a row</span>
            </div>
            <br />
            <div style={{ overflowX: "scroll" }}>
                <table className='table is-bordered is-striped is-fullwidth' >
                    <thead>
                        <tr>
                            {
                                columns.map((colName: string) =>
                                    <th key={colName}>
                                        {colName}
                                    </th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            visiblePairs.map((pair: AssetPair) =>
                                <EditableRow
                                    nonEditableRows={["id", "name"]}
                                    key={pair.name}
                                    columns={columns}
                                    columnValues={pair}
                                    updatePair={updatePair}
                                />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </section>
}

export default EditablePairsTable;