import React from 'react';
import PropTypes from 'prop-types'
import SpreadExporter from './components/SpreadExporter'
import { ApiClient } from './sharedInterfaces';
import DataExplorer from './components/DataExplorer';

interface DatastoreProps {
    apiClient: ApiClient
}

function Datastore(props: DatastoreProps) {

    return <section className="section">

        <div className="container">

        <article className="box has-background-light">
            <h2 className="title">Data Exporter</h2>

            <div className="columns">
                <div className="column">
                    <DataExplorer apiClient={props.apiClient} />
                </div>
            </div>
        </article> 

            <SpreadExporter defaultPair='BTC-USD' apiClient={props.apiClient} />


        </div>
    </section>
}

Datastore.propTypes = {
    apiClient: PropTypes.object.isRequired,
};

export default Datastore
