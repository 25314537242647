import React, { useState } from 'react';

interface NewRowModalProps {
    addNewAssetPair: Function;
    onClose: any,
    displayModal: boolean
}

function NewRowModal(props: NewRowModalProps) {

    const initialFormFields: any = {
        "asset": "",
        "cross": "",
        "asset_type": "",
        "binance_id": "",
        "bitfinex_id": "",
        "bitmex_id": "",
        "bitstamp_id": "",
        "coinbase_id": "",
        "ftx_id": "",
        "gemini_id": "",
        "huobi_id": "",
        "kraken_id": "",
        "okex_id": "",
        "sfox_id": "",
    }
    const requiredFields = ["asset", "cross", "asset_type"]

    const [formFields, setFormFields] = useState(initialFormFields)

    const allFields = Object.keys(formFields);

    function onFieldInputChange(event: any, columnName: string) {
        let newFieldState: any = JSON.parse(JSON.stringify(formFields)); // deep copy though not technically required here
        newFieldState[columnName] = event.target.value;
        setFormFields(newFieldState)
    }

    function convertEmptyStringsToNull(obj: any) {
        let deepCopy = JSON.parse(JSON.stringify(obj));
        Object.keys(deepCopy).forEach(key => deepCopy[key] = deepCopy[key] === '' ? null : deepCopy[key]);
        return deepCopy;
    }

    function getPlaceholder(columnName: string) {
        if (columnName === "asset_type") return "Must be either 'SPOT' or 'PERP'";
        else if (requiredFields.includes(columnName)) return "required";
        else return "Default is NULL";
    }

    function columnNameValueIsValid(columnName: string) {
        if (columnName === "asset_type") return formFields[columnName] === "SPOT" || formFields[columnName] === "PERP";
        else if (requiredFields.includes(columnName)) return formFields[columnName] !== "";
        else return true;
    }

    return <div className={props.displayModal ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Create New Pair</p>
                <button
                    className="delete"
                    onClick={() => {
                        props.onClose();
                        setFormFields(initialFormFields);
                    }}
                    aria-label="close"
                />
            </header>
            <section className="modal-card-body">
                {allFields.map(columnName =>
                    <div className="field" key={`${columnName}_input`}>
                        <label className="label">{columnName}</label>
                        <div className="control">
                            <input
                                className={columnNameValueIsValid(columnName) ? "input" : "input is-danger"}
                                type="text"
                                placeholder={getPlaceholder(columnName)}
                                value={formFields[columnName]}
                                onChange={(e) => onFieldInputChange(e, columnName)}
                            />
                            {!columnNameValueIsValid(columnName) ?
                                <p className="help is-danger">
                                    This field is required
                                </p>
                                : null}
                        </div>
                    </div>
                )}
            </section>
            <footer className="modal-card-foot">
                <fieldset disabled={requiredFields.filter(field => !columnNameValueIsValid(field)).length !== 0}>
                    <button
                        className="button is-success"
                        onClick={() => {
                            props.addNewAssetPair(convertEmptyStringsToNull(formFields));
                            props.onClose();
                            setFormFields(initialFormFields);
                        }}>
                        Save changes
                    </button>
                </fieldset>
                <button
                    className="button"
                    onClick={() => {
                        props.onClose();
                        setFormFields(initialFormFields);
                    }}>
                    Cancel
                </button>
            </footer>
        </div>
    </div>
}

export default NewRowModal;