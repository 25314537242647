import React from 'react';
import { AssetPair } from "../sharedInterfaces";
import InputCellFlipper from "./InputCellFlipper";


interface EditableRowProps {
    columns: string[],
    columnValues: AssetPair,
    updatePair: Function,
    nonEditableRows: string[]
}

function EditableRow(props: EditableRowProps) {

    return (
        <tr>
            {props.columns.map((column: string, index: number) => {
                const columnName = props.columns[index];
                return <InputCellFlipper
                    key={`${column}_${columnName}`}
                    value={props.columnValues[column as keyof AssetPair] === null ? "" : props.columnValues[column as keyof AssetPair]!}
                    containingRow={props.columnValues}
                    columnName={columnName}
                    isEditable={!props.nonEditableRows.includes(columnName)}
                    updatePair={props.updatePair}
                />
            }

            )}
        </tr>
    )
}

export default EditableRow;