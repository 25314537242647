//@ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from './Autocomplete';
import { ApiClient, AssetPair } from '../sharedInterfaces';

interface PairsButtonsProps {
    exchangePrefix: string,
    onToggleAssetPair: Function,
    selectedPairs: AssetPair[],
    key: string,
    apiClient: ApiClient,
    setSelectedPairs: Function
}

interface PairsButtonsState {
    error: any,
    isLoaded: boolean,
    allItems: AssetPair[],
    selectedItems: AssetPair[],
    exchangePrefix: string
}

class PairsButtons extends React.Component<PairsButtonsProps, PairsButtonsState> {
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: PairsButtonsProps) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            allItems: [],
            selectedItems: props.selectedPairs,
            exchangePrefix: props.exchangePrefix
        };
    }

    fetchPairs() {
        this.props.apiClient.get(`/api/candlestore/pairs`)
            .then((res: Response) => res.json())
            .then(
                (result: []) => {
                    const allItems = result.sort((a, b) => (a.name > b.name) ? 1 : -1);
                    this.setState({
                        isLoaded: true,
                        allItems,
                    });
                },
                (error: any) => {
                    console.log(error);
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidMount() {
        this.fetchPairs()
    }

    componentDidUpdate(prevProps: PairsButtonsProps) {
        if (this.props.exchangePrefix !== prevProps.exchangePrefix) {
            this.setState({ exchangePrefix: this.props.exchangePrefix })
        }
    }

    render() {

        const { error, isLoaded, allItems } = this.state;
        if (error) {
            return <div className="notification is-danger">
                {error.message}
            </div>;
        } else if (!isLoaded) {
            return <progress className="progress is-small is-primary" max="100">15%</progress>;
        } else {
            return (
                <>
                    {/* this allows you to see all options without having to type anything: */}
                    {/* <div className="tile select is-multiple is-3">
                            <select multiple size="8">
                                {allItems.filter(item => item[`${this.props.exchangePrefix}_id`] !== null && !this.props.selectedPairs.includes(item)).map(item => (
                                    <option key={item.id} value={item.name} onClick={() => {
                                        console.log(item)
                                        this.props.onToggleAssetPair(item)
                                    }}
                                    >{item.name}</option>
                                ))}
                            </select>
                        </div> */}
                    <label className="label">Exchange Pairs</label>
                    <div className="level">
                        <div className='level-left'>
                            <div className="level-item">
                            <Autocomplete
                                name="Pairs"
                                placeholder="Begin typing an asset"
                                //@ts-ignore
                                data={allItems.filter((item: AssetPair) => item[`${this.props.exchangePrefix}_id`] !== null && !this.props.selectedPairs.includes(item))}
                                selectItem={(item: AssetPair) => this.props.onToggleAssetPair(item)}
                            />
                            </div>
                            <div className="button is-small level-item" onClick={() => this.props.setSelectedPairs(allItems.filter((item: AssetPair) => item[`${this.props.exchangePrefix}_id`]))}>
                                Select All
                            </div>
                            <div className="button is-small level-item" onClick={() => this.props.setSelectedPairs([])}>
                                Deselect All
                            </div>

                        </div>

                    </div>

                    <div className="tile is-9 field is-grouped is-grouped-multiline">
                        {this.props.selectedPairs.map(item => (
                            <p className="control" key={item.id}>
                                <span className="tag is-rounded is-medium is-primary">
                                    {item.name}
                                    <button
                                        className="delete"
                                        onClick={() => this.props.onToggleAssetPair(item)}
                                    >
                                    </button>
                                </span>
                            </p>
                        ))
                        }
                    </div>

                </>
            )
        }
    }
}

export default PairsButtons
