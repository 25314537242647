
import React from 'react';
import PropTypes from 'prop-types';
import { ApiClient, PriceObject } from '../sharedInterfaces';
import { filterDailyByTimestamp, getDateByNumDaysBackUTC, startOfDayUTC, startOfPreviousDayUTC } from '../services/ExchangeService';

interface DailyPriceExportProps {
    apiClient: ApiClient
}

interface DailyPriceState {
    exchange: string,
    loading: boolean,
    items: PriceObject[],
    pairs: string[],
    errors: any[],
    retrieved: number,
    pair: string,
    csvLink: string,
    sleep: string,
    days: string,
    timesToRecheckStability: number,
    retrievingPrices: boolean,
}

interface Task {
    pair: string,
    done: boolean,
    retries: number
}

interface FTXPriceObject {
    startTime: string,
    time: number,
    open: number,
    high: number,
    low: number,
    close: number,
    volume: number
}

export default class DailyPriceExport extends React.Component<DailyPriceExportProps, DailyPriceState> {
    static FIVE_HUNDRED_THOUSAND = 500000;
    static propTypes = {
        apiClient: PropTypes.object.isRequired,
    };

    constructor(props: DailyPriceExportProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            exchange: "coinbase",
            loading: false,
            items: [],
            pairs: [],
            errors: [],
            retrieved: 0,
            pair: "",
            csvLink: "",
            sleep: "0",
            days: "1",
            timesToRecheckStability: 1,
            retrievingPrices: false,
        };
    }

    async handleSubmit(event: any) {
        event.preventDefault();

        console.log(event)

        this.setState({ items: [], errors: [], loading: true })

        let exchange = this.state.exchange;

        let pairs = await this.props.apiClient.get(`/api/exchanges/${exchange}/pairs?usdonly=true`).then((res: Response) => res.json())

        let filteredPairs = [];

        for (const pair of pairs) {
            let statsUrl = `/proxy/coinbase/products/${pair}/stats`;
        
            let retries = 3;
            let done = false;
        
            while (retries > 0 && !done) {
                const pairStatsResponse = await this.props.apiClient.get(statsUrl);
                if (pairStatsResponse.ok) {
                    const pairStats = await pairStatsResponse.json();
                    if (parseFloat(pairStats.volume) * parseFloat(pairStats.last) > DailyPriceExport.FIVE_HUNDRED_THOUSAND) {
                        filteredPairs.push(pair);
                    }
                    done = true;
                } else if (pairStatsResponse.status === 400) {
                    done = true;
                } else {
                    retries--;
                    if (retries === 0) {
                        console.log(`Failed to retrieve data for ${pair} after 3 retries.`);
                    }
                }
            }
        }
        

        pairs = filteredPairs;

        console.log(`retrieving ${pairs.length} pairs`)

        this.setState({ pairs })

        let retrieved = 0;

        let items: PriceObject[] = [];

        let tasks: Task[] = [];
        for (let pair of pairs) {
            tasks.push({ pair, done: false, retries: 1 })
        }
        let stableItemsAddedToTable: PriceObject[] = [];
        let processed: any = {};
        this.setState({ retrievingPrices: true })
        while (retrieved < pairs.length) {
            for (let task of tasks) {

                if (!task.done) {
                    this.setState({
                        pair: task.pair
                    })

                    try {
                        let pricesResponse = await this.getHistoricalDataByExchange(task);

                        if (pricesResponse.status === 404) {
                            let errors = this.state.errors.concat({ message: `Received a 404 (Not Found error), the pair "${task.pair}" cannot be found in exchange: "${this.state.exchange}", it will not be included.` })
                            retrieved++;
                            this.setState({
                                errors
                            })
                            task.done = true
                            continue
                        }

                        const priceObjects: PriceObject[] = await this.getPriceObjectsFromResponse(pricesResponse, task, processed);

                        //first row contains partial data for today
                        let endTimestamp = startOfPreviousDayUTC(Date.now()).getTime();
                        let filteredPrices = filterDailyByTimestamp(parseInt(this.state.days), endTimestamp, priceObjects);
                        for (let i = 0; i < filteredPrices.length; i++) {
                            const curr = filteredPrices[i];
                            const processedKey = `${curr.pair}_${curr.date}_${exchange}`;
                            if (!processed[processedKey]) processed[processedKey] = [];
                            processed[processedKey].push(curr.close);
                        }


                        // remove from the table any item of current pair that has not been added as stable to the table
                        items = this.state.items.filter(item => item.pair !== task.pair || stableItemsAddedToTable.some(stableItem => stableItem.date.getTime() === item.date.getTime() && stableItem.pair === item.pair));

                        // don't add any rows that have already been added (while stable) to table
                        filteredPrices = filteredPrices.filter(item => !stableItemsAddedToTable.some(stableItem => stableItem.date.getTime() === item.date.getTime() && stableItem.pair === item.pair));
                        const stablePricesToBeAdded = filteredPrices.filter(item => item.numConsecutiveStable === this.state.timesToRecheckStability + 1 /* i.e. is stable */);

                        if (stablePricesToBeAdded.length === 1) {
                            const dayCandleToBeAdded = stablePricesToBeAdded[0];
                            const vwap = await this.calculateVWAP(dayCandleToBeAdded, task.retries);
                            let closeTokens = dayCandleToBeAdded.close.toString().split('.');
                            let decimalPlaces = closeTokens.length === 2 ? closeTokens[1].length : 0;
                            let roundedVwap = parseFloat(vwap.toFixed(decimalPlaces + 1));
                            dayCandleToBeAdded.vwap = roundedVwap;
                        }
                        stableItemsAddedToTable = stableItemsAddedToTable.concat(stablePricesToBeAdded);
                        items = items.concat(filteredPrices)
                        items.sort((a: PriceObject, b: PriceObject) => {
                            if (a.time > b.time) return -1;
                            if (a.time < b.time) return 1;

                            if (a.pair > b.pair) return 1;
                            if (a.pair < b.pair) return -1;

                            return 0;
                        })
                        if (filteredPrices.filter(priceObj => priceObj.numConsecutiveStable !== this.state.timesToRecheckStability + 1).length === 0) {
                            task.done = true;
                            retrieved++;
                        }

                        this.setState({
                            retrieved, items
                        })

                    } catch (e: any) {
                        console.log(e);
                        let errors = this.state.errors.concat(e)
                        task.retries = task.retries + 1;
                        console.log(errors)
                        this.setState({
                            retrieved, errors
                        })
                    }

                    await this.sleep(parseInt(this.state.sleep))
                }
            }
        }
        this.setState({ retrievingPrices: false })

        let csvArray = ["Pair,Timestamp,Date,Open,High,Low,Close,Volume,VWAP"]
        for (let row of items) {
            const timestamp = row.date.toISOString().replace("T", " ").split(".")[0]
            const date = `${row.date.getUTCMonth() + 1}/${row.date.getUTCDate()}/${row.date.getUTCFullYear()}`
            csvArray.push(`${row.pair},"${timestamp}","${date}",${row.open},${row.high},${row.low},${row.close},${row.volume},${row.vwap}`)
        }
        let csv = csvArray.join("\n")

        let csvFile = new Blob([csv], { type: "text/csv" });
        let csvLink = window.URL.createObjectURL(csvFile);
        const link = document.createElement('a');
        link.href = csvLink;
        link.click();

        this.setState({ loading: false, csvLink, pair: "" })
    }

    async calculateVWAP(priceObject: PriceObject, retries: number) {
        const assetPair = priceObject.pair;
        const date = priceObject.date;
        const start = startOfDayUTC(date.getTime());
        const end = startOfDayUTC(date.getTime());
        end.setUTCHours(23, 59, 59, 999); // set end time to the end of the day

        // variables to store the cumulative TPV and volume
        let cumulativeTPV = 0;
        let cumulativeVolume = 0;

        // multiple API requests to cover the full day's minute candles
        const numMinutesPerBlock = 290;  // request no more than 300 minutes at a time, do 290 at a time just to be safe
        let requestStart = start;
        let requestEnd = new Date(requestStart.getTime() + numMinutesPerBlock * 60 * 1000);

        while (requestStart < requestEnd) {
            const minuteGranularity = 60;
            const requestUrl = `/proxy/coinbase/products/${assetPair}/candles?granularity=${minuteGranularity}&start=${requestStart.toISOString()}&end=${requestEnd.toISOString()}`;
            const minuteCandlesResponse = await this.props.apiClient.get(requestUrl);
            if (!minuteCandlesResponse.ok) {
                if (minuteCandlesResponse.status === 429) {
                    throw new Error("API rate limited while retrieving minute candles for: " + assetPair + ". Auto-retrying [" + retries + "]")
                } else {
                    throw new Error("Received some error while retrieving minute candles for: " + assetPair + ". Auto-retrying [" + retries + "]")
                }
            }
            const minuteCandles = await minuteCandlesResponse.json();

            // Calculate the TPV and volume for each minute in this time range
            for (let i = 0; i < minuteCandles.length; i++) {
                const candle = minuteCandles[i];
                const typicalPrice = (candle[1] + candle[2] + candle[4]) / 3;
                const TPV = typicalPrice * candle[5];
                cumulativeTPV += TPV;
                cumulativeVolume += candle[5];
            }
            requestStart = new Date(requestEnd);
            requestStart = new Date(requestStart.getTime() + 60 * 1000); // request is end-inclusive so in next request we exclude the previous end minute
            if (requestEnd >= end) break;
            requestEnd = new Date(requestStart.getTime() + numMinutesPerBlock * 60 * 1000);
            if (requestEnd > end) {
                requestEnd = end;
            }
        }

        // Calculate the VWAP for the entire day
        return cumulativeTPV / cumulativeVolume;
    }

    async getHistoricalDataByExchange(task: Task) {
        if (this.state.exchange === "coinbase") {
            return await this.props.apiClient.get(`/proxy/coinbase/products/${task.pair}/candles?granularity=86400&ts=${Date.now()}`)
        } else if (this.state.exchange === "ftx") {
            const startTime = getDateByNumDaysBackUTC(Date.now(), parseInt(this.state.days)).getTime() / 1000;
            return await this.props.apiClient.get(`proxy/ftx/markets/${task.pair}/candles?resolution=86400&start_time=${startTime}&ts=${Date.now()}`)
        }
        throw new Error(`The selected exchange "(${this.state.exchange})" has no historical data endpoint to connect to.`);
    }

    async getPriceObjectsFromResponse(pricesResponse: Response, task: Task, processed: any): Promise<PriceObject[]> {
        if (this.state.exchange === "coinbase") {
            const prices: number[][] = await pricesResponse.json()

            if (prices.length === 0) {
                throw new Error("API rate limited while retrieving " + task.pair + ". Auto-retrying [" + task.retries + "]")
            }

            const priceObjects = prices.map((r: number[]) => {
                const currDate = new Date(r[0] * 1000);
                const previousClosesArray = processed[`${task.pair}_${currDate}_${this.state.exchange}`];
                const lastSameCloses = previousClosesArray ? previousClosesArray.slice(-this.state.timesToRecheckStability).filter((close: number) => close === r[4]) : [];
                const numConsecutiveSameCloses = lastSameCloses.length;
                return {
                    pair: task.pair,
                    time: r[0] * 1000,
                    date: currDate,
                    low: r[1],
                    high: r[2],
                    open: r[3],
                    close: r[4],
                    volume: r[5],
                    numConsecutiveStable: numConsecutiveSameCloses + 1,
                    vwap: -1
                }
            });
            return priceObjects;
        } else if (this.state.exchange === "ftx") {
            const resp = await pricesResponse.json();

            if (!resp.success) {
                throw new Error("Error retrieving " + task.pair + " likely because the service was unavailable. Auto-retrying [" + task.retries + "]")
            }

            const prices = resp.result;
            const priceObjects: PriceObject[] = prices.map((singleDay: FTXPriceObject) => {
                const currDate = new Date(singleDay.startTime);
                const previousClosesArray = processed[`${task.pair}_${currDate}_${this.state.exchange}`];
                const lastSameCloses = previousClosesArray ? previousClosesArray.slice(-this.state.timesToRecheckStability).filter((close: number) => close === singleDay.close) : [];
                const numConsecutiveSameCloses = lastSameCloses.length;
                return {
                    pair: task.pair,
                    time: currDate.getTime(),
                    date: currDate,
                    low: singleDay.low,
                    high: singleDay.high,
                    open: singleDay.open,
                    close: singleDay.close,
                    volume: singleDay.volume,
                    numConsecutiveStable: numConsecutiveSameCloses + 1
                }
            });
            return priceObjects;

        }
        throw new Error(`The selected exchange "(${this.state.exchange})" does not have a PriceObject extraction implementation.`)
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    clear() {
        this.setState({
            retrieved: 0,
            items: [],
            errors: [],
            loading: false
        })
    }

    handleApiWaitOptionChange = (changeEvent: any) => {
        this.setState({
            sleep: changeEvent.target.value
        });
    };

    handleStabilityCheckOptionChange = (changeEvent: any) => {
        this.clear();
        this.setState({
            timesToRecheckStability: parseInt(changeEvent.target.value)
        });
    };


    render() {
        return (
            <article className="box has-background-light">
                <h2 className="title">Daily Price Export</h2>
                <form onSubmit={this.handleSubmit}>
                    <nav className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="field is-grouped">
                                    <div className="control">
                                        <div className="select">
                                            <select onChange={(event) => { this.setState({ exchange: event.target.value }) }}>
                                                <option value="coinbase">Coinbase</option>
                                                <option value="ftx">FTX</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="control">
                                        <div className="select">
                                            <select onChange={(event) => { this.setState({ days: event.target.value }) }}>
                                                <option value="1">1 Day</option>
                                                <option value="2">2 Days</option>
                                                <option value="3">3 Days</option>
                                                <option value="4">4 Days</option>
                                                <option value="5">5 Days</option>
                                                <option value="6">6 Days</option>
                                                <option value="7">1 Week</option>
                                            </select>
                                        </div>
                                    </div>

                                    {this.state.loading === false ? <div className="control">
                                        <button className="button is-primary" type="submit">Retrieve</button>
                                    </div> : null}
                                    {(this.state.loading === false && this.state.items.length) ? <div className="control">
                                        <a className="button is-link" href={this.state.csvLink}>Download</a>
                                    </div> : null}

                                    {(this.state.loading === false && this.state.items.length) ? <div className="control">
                                        <button className="button" onClick={this.clear.bind(this)}>Clear</button>
                                    </div> : null}
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item">
                                <div className="field is-grouped">
                                    <div className="control">
                                        <div className="select">
                                            <select onChange={this.handleApiWaitOptionChange}>
                                                <option value="0">0 ms</option>
                                                <option value="50">50 ms</option>
                                                <option value="100">100 ms</option>
                                            </select>
                                        </div>
                                        <span className="icon has-text-info" title="Choose how many ms to wait in between API calls">
                                            <i className="fas fa-circle-question"></i>
                                        </span>
                                    </div>
                                    <div className="control">
                                        <div className="select">
                                            <select onChange={this.handleStabilityCheckOptionChange} disabled={this.state.retrievingPrices} defaultValue={1}>
                                                <option value="0">None</option>
                                                <option value="1">Once</option>
                                                <option value="2">Twice</option>
                                                <option value="3">Three times</option>
                                            </select>
                                        </div>
                                        <span className="icon has-text-info" title="Choose number of times to re-check the prices to confirm that they are stable. 0 means don't re-check, just give me the first result. 1 means check one extra time that the 'close' did not change. 2 means check two extra times.">
                                            <i className="fas fa-circle-question"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </form>
                {this.state.loading === true ? <div className="my-4">
                    {this.state.pair ? `Retrieving ${this.state.pair}` :
                        <div>filtering out low-volume assets...<span className="icon"><i className="fas fa-spinner fa-pulse"></i></span></div>}
                    <progress className="progress is-primary" value={this.getNumStableRetrievals()} max={this.getMaxRetrievals()}>15%</progress>
                </div> : null}
                {this.state.errors.map((error, index) => (
                    <div key={index} className="notification is-danger mt-2">
                        {error.message}
                        <button
                            className="delete"
                            onClick={() => this.removeError(error)}
                        >
                        </button>
                    </div>
                ))}

                {this.state.items.length ? <div className="my-2">
                    <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th><abbr title="Pair">Pair</abbr></th>
                                <th><abbr title="Date">Date</abbr></th>
                                <th><abbr title="Open">O</abbr></th>
                                <th><abbr title="High">H</abbr></th>
                                <th><abbr title="Low">L</abbr></th>
                                <th><abbr title="Close">C</abbr></th>
                                <th><abbr title="Volume">V</abbr></th>
                                <th><abbr title="VWAP">VWAP</abbr></th>
                                <th><abbr title="Progress">Stable</abbr></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.items.map(item => (
                                <tr key={item.pair + '-' + item.date}>
                                    <th>{item.pair}</th>
                                    <th>{item.date.toUTCString()}</th>
                                    <td>{item.open}</td>
                                    <td>{item.high}</td>
                                    <td>{item.low}</td>
                                    <td>{item.close}</td>
                                    <td>{item.volume}</td>
                                    <td>{item.vwap === -1 ? "N/A" : item.vwap}</td>
                                    <td>
                                        {Array.from({ length: item.numConsecutiveStable - 1 }).map((_item, index) => <span className="icon" key={`${item.pair}_icon_${index}`}>
                                            <i className="fa-solid fa-circle-check has-text-success" />
                                        </span>)}
                                        {Array.from({ length: this.state.timesToRecheckStability + 1 - item.numConsecutiveStable }).map((_item, index) => <span className="icon" key={`${item.pair}_icon_${index}`}>
                                            <i className="fas fa-spinner fa-pulse"></i>
                                        </span>)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> : null}
            </article>
        );
    }
    getMaxRetrievals(): string | number | undefined {
        return this.state.pairs.length * (this.state.timesToRecheckStability + 1) * parseInt(this.state.days);
    }

    getNumStableRetrievals(): number {
        return this.state.items.map(item => item.numConsecutiveStable).reduce((partialSum, newSum) => partialSum + newSum, 0);
    }

    removeError(error: any): void {
        let newErrors = this.state.errors.filter(currError => error !== currError);
        this.setState({ errors: newErrors })
    }

}